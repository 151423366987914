




































































































































import AddItemButton from '@/components/explore/AddItemButton.vue';
import { ArrayWrapper } from '@/lib-on-fhir';
import ExplorerResolver from '@/lib-on-fhir/vue-example/resource-explorer/ExplorerResolver.vue';
import { config } from '@/services/config';
import Vue from 'vue';

export default Vue.extend({
    props: {
        element: {
            type: ArrayWrapper,
        },
        depth: {
            type: Number,
            default: 0,
        },
    },

    components: { ExplorerResolver, AddItemButton },
    data: () => ({
        ArrayWrapper,
        ExplorerResolver,
        newFieldKey: '',
    }),

    computed: {
        availableFieldsToAdd(): any {
            return this.element.getAvailableFieldsToAdd();
        },

        showExpanded(): boolean {
            return (
                this.element.$type.cardinality.max > 1 ||
                this.element.items.length > 1 ||
                config.showDebug
            );
        },
    },
    methods: {
        addNewField(key: string) {
            const data = this.availableFieldsToAdd[key];
            if (!data) {
                alert('Invalid: ' + key);
                return;
            }
            this.element.addNewField(data.definition);
        },
        deleteElement() {
            this.element.deleteValue();
        },
    },
});
